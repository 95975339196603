import { OrganizationDetailsMembers } from '../components/Organization/Details';

export function OrganizationMembersRoute() {
  return (
    <OrganizationDetailsMembers className='pl-8 pt-10 pr-12.5' adminView />
  );
}

export const Component = OrganizationMembersRoute;

export function clientLoader() {
  return null;
}
